import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    message: String
  }
  static targets = ["input"]

  updateValue(event) {
    this.inputTarget.value = event.target.innerText;
  }

  hide(_event) {
    document.querySelector("#message-suggestion").classList.add("hidden");
  }

  minimize(_event) {
    document.querySelectorAll(".message-suggestion-minimize").forEach((e) => e.classList.toggle("hidden"));
  }
}
