import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["send", "picture"]

  formReset() {
    this.element.reset()
    this.pictureTarget.blur()
  }

  sendMsg(event) {
    if (event.keyCode == 13 && !event.shiftKey) {
      event.preventDefault()
      this.sendTarget.click()
    }
  }

  changeStatus() {
    this.pictureTarget.focus()
  }
}
