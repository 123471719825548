import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    connect() {
        this.resize()
        window.addEventListener("resize", this.resize.bind(this))
    }

    resize() {
        this.element.style.height = window.innerHeight + "px"
    }

}
