// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import NestedForm from "stimulus-rails-nested-form"
import CheckboxSelectAll from "stimulus-checkbox-select-all"

window.Stimulus = Application.start()
const context = require.context("../controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

// Import and register all TailwindCSS Components
import { Alert, Autosave, Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
Stimulus.register("alert", Alert)
Stimulus.register("autosave", Autosave)
Stimulus.register("dropdown", Dropdown)
// Stimulus.register("modal", Modal)
// Stimulus.register("tabs", Tabs)
Stimulus.register("popover", Popover)
Stimulus.register("toggle", Toggle)
Stimulus.register("slideover", Slideover)
Stimulus.register("nested-form", NestedForm)
Stimulus.register("checkbox-select-all", CheckboxSelectAll)
