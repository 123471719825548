import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "rooms"]

  searchUserName() {
    let searchString = this.inputTarget.value.toUpperCase()
    let li = this.roomsTarget.getElementsByTagName("li")

    for (let i = 0; i < li.length; i++) {
      let atag = li[i].getElementsByTagName("a")[0]
      let ptag = atag.getElementsByTagName("p")[0]
      let userName = ptag.innerText || ptag.textContent

      if (userName.toUpperCase().indexOf(searchString) > -1) {
        li[i].style.display = ""
      } else {
        li[i].style.display = "none"
      }
    }
  }
}
