import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "category",
    "data",
    "text",
    "uri",
    "mode",
    "initial",
    "max",
    "min",
    "label",
    "dataInput",
    "textInput",
    "uriInput",
    "modeInput",
    "initialInput",
    "maxInput",
    "minInput",
    "labelInput",
  ]

  get category() {
    return this.categoryTarget.options[this.categoryTarget.selectedIndex].value
  }

  connect() {
    this.switchCategory()
  }

  changeCategory() {
    this.switchCategory()
  }

  switchCategory() {
    this.clearAllOptions()
    this.switchPostback()
    this.switchMessage()
    this.switchURI()
    this.switchDatetimepicker()
    this.switchCamera()
    this.switchCameraRoll()
    this.switchLocation()
  }

  clearAllOptions() {
    this.labelTarget.style.display = "none"
    this.dataTarget.style.display = "none"
    this.textTarget.style.display = "none"
    this.uriTarget.style.display = "none"
    this.modeTarget.style.display = "none"
    this.initialTarget.style.display = "none"
    this.maxTarget.style.display = "none"
    this.minTarget.style.display = "none"
  }

  switchPostback() {
    if (this.category === "postback") {
      this.labelTarget.style.display = "block"
      this.labelInputTarget.required = true
      this.dataTarget.style.display = "block"
      this.dataInputTarget.required = true
      this.textTarget.style.display = "block"
      this.textInputTarget.required = true
    }
  }

  switchMessage() {
    if (this.category === "message") {
      this.labelTarget.style.display = "block"
      this.labelInputTarget.required = true
      this.textTarget.style.display = "block"
      this.textInputTarget.required = true
    }
  }

  switchURI() {
    if (this.category === "uri") {
      this.labelTarget.style.display = "block"
      this.labelInputTarget.required = true
      this.uriTarget.style.display = "block"
      this.uriInputTarget.required = true
    }
  }

  switchDatetimepicker() {
    if (this.category === "datetimepicker") {
      this.labelTarget.style.display = "block"
      this.labelInputTarget.required = true
      this.dataTarget.style.display = "block"
      this.dataInputTarget.required = true
      this.modeTarget.style.display = "block"
      this.modeInputTarget.required = true
      this.initialTarget.style.display = "block"
      this.maxTarget.style.display = "block"
      this.minTarget.style.display = "block"
    }
  }

  switchCamera() {
    if (this.category === "camera") {
      this.labelTarget.style.display = "block"
      this.labelInputTarget.required = true
    }
  }

  switchCameraRoll() {
    if (this.category === "cameraRoll") {
      this.labelTarget.style.display = "block"
      this.labelInputTarget.required = true
    }
  }

  switchLocation() {
    if (this.category === "location") {
      this.labelTarget.style.display = "block"
      this.labelInputTarget.required = true
    }
  }
}
