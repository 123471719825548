import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  toggle(event) {
    event.preventDefault()

    let group_id = event.currentTarget.attributes["target-group-id"].value
    let group_rows = document.querySelectorAll(`[group-id="${group_id}"]`)

    group_rows.forEach((group_row) => {
      group_row.classList.toggle("hidden")
    })
  }
}
