import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["category", "fb_page_url", "entry_code"]

  get category() {
    return this.categoryTarget.options[this.categoryTarget.selectedIndex].value
  }

  connect() {
    this.switchCategory()
  }

  changeCategory() {
    this.switchCategory()
  }

  switchCategory() {
    this.clearAllOptions()
    this.switchLine()
    this.switchFacebook()
  }

  clearAllOptions() {
    this.fb_page_urlTarget.style.display = "none"
    this.entry_codeTarget.style.display = "none"
  }

  switchLine() {
    if (this.category === "Line") {
      this.entry_codeTarget.style.display = "block"
    }
  }

  switchFacebook() {
    if (this.category === "Facebook") {
      this.fb_page_urlTarget.style.display = "block"
    }
  }
}
