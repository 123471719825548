import { Controller } from "@hotwired/stimulus"
import * as Choices from "choices.js"

export default class extends Controller {
  connect() {
    new Choices(this.element, {
      delimiter: ",",
      removeItemButton: true,
      duplicateItemsAllowed: false,
      editItems: true,
      addItemText: (value) => {
        return `按 Enter 新增 <b>"${value}"</b>`
      },
      uniqueItemText: "重複的關鍵字",
    })
  }
}
