import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

export default class extends Controller {
  static values = { roomId: String }
  connect() {
    const roomId = this.roomIdValue
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        post("/api/received_all", { body: { room_id: roomId } })
      }
    })
  }
}
