import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["nav", "chevron"]

  display() {
    this.navTarget.classList.toggle("hidden")
  }

  toggle() {
    const scalableElement = this.element.querySelectorAll(".scalable")
    const iconElement = this.element.querySelectorAll(".icon")
    if (screen.width < 1280) {
      this._collapseNavbar(scalableElement, "hidden", iconElement, "w-5", "w-56", "rotate-180")
    } else {
      this._collapseNavbar(scalableElement, "xl:block", iconElement, "xl:w-4", "xl:w-56", "xl:rotate-180")
    }
  }

  _collapseNavbar(elements, status, iconElement, size, navWidth, direction) {
    for (const [key] of Object.entries(elements)) {
      elements[key].classList.toggle(status)
    }
    for (const [key] of Object.entries(iconElement)) {
      iconElement[key].classList.toggle(size)
      if (screen.width < 1280) {
        iconElement[key].classList.toggle("w-4")
      }
    }
    this.navTarget.classList.toggle(navWidth)
    this.chevronTarget.classList.toggle(direction)
  }
}
