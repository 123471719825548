import { Controller } from "@hotwired/stimulus"
import { Modal } from "flowbite"

export default class extends Controller {
  connect() {
    this.modal = new Modal(this.element, {})
  }

  disconnect() {
    this.modal.hide()
  }

  open() {
    this.modal.show()
  }

  close() {
    this.modal.hide()
  }
}
