import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["toggleButton", "toggleWrapper", "toggleCross", "toggleCheck"]
  static values = { mainColor: String, currentUserId: Number }
  connect() {}

  toggle() {
    const mainColor = this.mainColorValue
    const currentUserId = this.currentUserIdValue

    this.toggleButtonTarget.classList.toggle(`bg-${mainColor}-500`)
    this.toggleButtonTarget.classList.toggle("bg-gray-200")
    this.toggleWrapperTarget.classList.toggle("translate-x-5")
    this.toggleWrapperTarget.classList.toggle("translate-x-0")
    this.toggleCrossTarget.classList.toggle("opacity-0")
    this.toggleCrossTarget.classList.toggle("duration-100")
    this.toggleCrossTarget.classList.toggle("ease-out")
    this.toggleCrossTarget.classList.toggle("opacity-100")
    this.toggleCrossTarget.classList.toggle("duration-200")
    this.toggleCrossTarget.classList.toggle("ease-in")

    this.toggleCheckTarget.classList.toggle("opacity-100")
    this.toggleCheckTarget.classList.toggle("duration-200")
    this.toggleCheckTarget.classList.toggle("ease-in")
    this.toggleCheckTarget.classList.toggle("opacity-0")
    this.toggleCheckTarget.classList.toggle("duration-100")
    this.toggleCheckTarget.classList.toggle("ease-out")

    // post to toggle_new_chat_sound_path with current_user.id
    Rails.ajax({
      type: "POST",
      url: `/users/toggle_new_chat_sound`,
      data: new URLSearchParams({ user_id: currentUserId }).toString(),
      success: function (response) {
        console.log(response)
      },
      error: function (err) {
        console.error(err)
      },
    })
  }
}
