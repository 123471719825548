import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"
import Bee from "@mailupinc/bee-plugin"

export default class extends Controller {
  static values = {
    onSaveAsTemplateUrl: String,
    onSaveUrl: String,
    signedId: String,
    template: String,
    tokenUrl: String,
    uid: String,
  }

  initialize() {
    this.initializeEditor()
  }

  async initializeEditor() {
    // Fetch token, config, template
    const token = await this.authentication()
    const config = await this.config()
    const template = await JSON.parse(this.templateValue)
    // Start the editor
    const bee = new Bee(token)
    bee.start(config, template, null, true)
  }

  async authentication() {
    const response = await post(this.tokenUrlValue, { responseKind: "json" })
    const result = await response.json
    if (response.ok) {
      const token = await JSON.parse(result.token)
      return token
    } else {
      throw new Error(result.error)
    }
  }

  async config() {
    const config = {
      uid: this.uidValue,
      container: "bee-plugin-container",
      language: "zh-HK",
      onSave: (jsonFile, htmlFile) => {
        this.onSave(jsonFile, htmlFile)
      },
      onSaveAsTemplate: (jsonFile) => {
        this.onSaveAsTemplate(jsonFile)
      },
      onSend: (htmlFile) => {
        console.log("onSend", htmlFile)
      },
      onError: (errorMessage) => {
        console.log("onError ", errorMessage)
      },
    }
    return config
  }

  async onSave(jsonFile, htmlFile) {
    const body = {
      htmlFile: btoa(unescape(encodeURIComponent(htmlFile))),
      jsonFile: btoa(unescape(encodeURIComponent(jsonFile))),
      signedId: this.signedIdValue,
    }
    const response = await post(this.onSaveUrlValue, { body: body, responseKind: "json" })
    const result = await response.json
    if (response.ok) {
      const timeNow = new Date().toLocaleString()
      console.log(`${result.message} @ ${timeNow}`)
    } else {
      throw new Error(result.error)
    }
  }

  async onSaveAsTemplate(jsonFile) {
    const body = {
      jsonFile: btoa(unescape(encodeURIComponent(jsonFile))),
      signedId: this.signedIdValue,
    }
    const response = await post(this.onSaveAsTemplateUrlValue, { body: body, responseKind: "json" })
    const result = await response.json
    if (response.ok) {
      const timeNow = new Date().toLocaleString()
      console.log(`${result.message} @ ${timeNow}`)
    } else {
      throw new Error(result.error)
    }
  }
}
