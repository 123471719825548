import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "tabPanel"]

  connect() {
    this.showTab(0)
  }

  change(event) {
    this.index = this.tabTargets.indexOf(event.target)
    this.showTab(this.index)
  }

  showTab(x) {
    this.tabPanelTargets.forEach((el, i) => {
      if (i === x) {
        el.classList.remove("hidden")
      } else {
        el.classList.add("hidden")
      }
    })

    this.tabTargets.forEach((el, i) => {
      if (i === x) {
        el.classList.add("active")
      } else {
        el.classList.remove("active")
      }
    })
  }

  get index() {
    return parseInt(this.data.get("index"))
  }

  set index(value) {
    this.data.set("index", value)
    this.showTab()
  }
}
