// GameController.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['modal'];

  connect() {
    // Do any setup required when the controller is connected.
  }

  showModal() {
    // Show the modal and set the iframe source to www.google.com
    this.modalTarget.classList.add('active');
    this.modalTarget.querySelector('iframe').src = 'https://d2drhksbtcqozo.cloudfront.net/casino/launcher.html?lang=en_US&channel=web&gameid=bananatown&moneymode=real&ticket=b0040b3bdde1a0b16d2f96686dda9be2&homeurl=https%3A%2F%2Fdaddy.betrnk.games%2Fexit_game&currency=USD&partnerid=1813&partner=betrnk&apex=1&fullscreen=false';
  }

  hideModal() {
    // Hide the modal and clear the iframe source
    this.modalTarget.classList.remove('active');
    this.modalTarget.querySelector('iframe').src = '';
  }
}
