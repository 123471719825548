import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static outlets = ["modal"]

  openModal() {
    this.modalOutlet.open()
  }

  openModalAfterTurboFrameLoad() {
    document.addEventListener("turbo:frame-load", () => {
      this.modalOutlet.open()
    })
  }

  closeModal() {
    this.modalOutlet.close()
  }
}
