import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["show", "pending", "following", "done"]

  switch_reply_user(e) {
    const room_id = document.getElementById("room_id").value
    Rails.ajax({
      type: "POST",
      url: `/rooms/${room_id}/switch_reply_user`,
      data: new URLSearchParams({ reply_user: e.target.value }).toString(),
      success: function (response) {
        console.log(response)
      },
      error: function (err) {
        console.error(err)
      },
    })
  }
}
