import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["textarea", "display"]

  connect() {
    this.count()
  }

  count() {
    var num = this.textareaTarget.value.length
    this.displayTarget.innerHTML = num
    if (num > 1000) {
      this.displayTarget.classList.add("text-red-500")
    } else {
      this.displayTarget.classList.remove("text-red-500")
    }
  }
}
