import { Controller } from "@hotwired/stimulus"
import moment from "moment"

export default class extends Controller {
  static values = { timestamp: Number }
  static targets = ["counter", "li", "mobileCounter"]

  connect() {
    if (this.isValidTimestamp()) {
      this.startInterval()
    }
  }

  isValidTimestamp() {
    return this.timestampValue && this.timestampValue !== "0"
  }

  startInterval() {
    // set interval to 1000ms
    this.interval = setInterval(() => {
      this.update()
    }, 1000)
  }

  update() {
    // get the current time as timestamp
    const now = moment().valueOf()
    // get the difference between the current time and the timestamp
    const diff = moment(now).diff(moment(this.timestampValue))
    // get the duration
    const duration = moment.duration(diff)
    // get the hours, minutes, and seconds
    const hours = duration.hours()
    const minutes = duration.minutes()
    const seconds = duration.seconds()

    // set the innerHTML of the element to the hours, minutes, and seconds
    // if duration is under 59 seconds, set the innerHTML to "Just now"
    // if duration is between 1 - 2 minutes, show ${hours}:${minutes}:${seconds} with a class of "text-warning"
    // if duration is between 2 - 5 minutes, show ${hours}:${minutes}:${seconds} with a class of "text-danger"

    const warningColor = "yellow-300"
    const alertColor = "red-300"
    const dangerColor = "red-500"

    const defaultTextColor = "text-gray-500"
    const warningClass = `text-${warningColor}`
    const alertClass = `text-${alertColor}`
    const dangerClass = `text-${dangerColor}`
    const warningBorderClass = `bg-${warningColor}`
    const alertBorderClass = `bg-${alertColor}`
    const dangerBorderClass = `bg-${dangerColor}`

    let displayText
    switch (true) {
      case duration.asSeconds() < 59:
        this.counterTarget.innerHTML = "Just now"
        this.mobileCounterTarget.innerHTML = "Just now"
        break
      case duration.asSeconds() < 120:
        displayText = minutes === 0 ? `${seconds} S` : `${minutes} M ${seconds} S`
        this.counterTarget.innerHTML = displayText
        this.mobileCounterTarget.innerHTML = displayText
        this.counterTarget.classList.remove(defaultTextColor)
        this.mobileCounterTarget.classList.remove(defaultTextColor)
        this.counterTarget.classList.add(warningClass)
        this.mobileCounterTarget.classList.add(warningClass)
        // this.liTarget.classList.remove("bg-white")
        // this.liTarget.classList.add(warningBorderClass, "border")
        break
      case duration.asSeconds() >= 120 && duration.asSeconds() < 3600:
        displayText = `${minutes} M ${seconds} S`
        this.counterTarget.innerHTML = displayText
        this.mobileCounterTarget.innerHTML = displayText
        this.counterTarget.classList.remove(defaultTextColor)
        this.mobileCounterTarget.classList.remove(defaultTextColor)
        this.counterTarget.classList.remove(warningClass)
        this.mobileCounterTarget.classList.remove(warningClass)
        this.counterTarget.classList.add(alertClass)
        this.mobileCounterTarget.classList.add(alertClass)
        // this.liTarget.classList.remove("bg-white")
        // this.liTarget.classList.remove(warningBorderClass, "border")
        // this.liTarget.classList.add(alertBorderClass, "border")
        break
      case duration.asSeconds() >= 3600:
        displayText = `${hours} H ${minutes} M ${seconds} S`
        this.counterTarget.innerHTML = displayText
        this.mobileCounterTarget.innerHTML = displayText
        this.counterTarget.classList.remove(defaultTextColor)
        this.mobileCounterTarget.classList.remove(defaultTextColor)
        this.counterTarget.classList.remove(alertClass)
        this.mobileCounterTarget.classList.remove(alertClass)
        this.counterTarget.classList.add(dangerClass)
        this.mobileCounterTarget.classList.add(dangerClass)
        // this.liTarget.classList.remove("bg-white")
        // this.liTarget.classList.remove(alertBorderClass, "border")
        // this.liTarget.classList.add(dangerBorderClass, "border")
        break
    }
  }
}
