import { Controller } from "@hotwired/stimulus"
import Litepicker from "litepicker"

export default class extends Controller {
  static targets = ["start", "end"]
  connect() {
    new Litepicker({
      element: this.startTarget,
      elementEnd: this.endTarget,
      singleMode: false,
      allowRepick: true,
    })
  }
}
