import { Controller } from "@hotwired/stimulus"
import Litepicker from "litepicker"

export default class extends Controller {
  static targets = ["panel", "by_order_times_inputs", "by_total_amount_inputs"]

  by_total_amount(event) {
    event.preventDefault()
    this._restorePanel(this.panelTarget)

    event.target.classList.replace("switch-btn-inactive", "switch-btn-active")
    this.by_total_amount_inputsTarget.classList.remove("hidden")
    this.by_order_times_inputsTarget.classList.add("hidden")
  }

  by_order_times(event) {
    event.preventDefault()
    this._restorePanel(this.panelTarget)

    event.target.classList.replace("switch-btn-inactive", "switch-btn-active")
    this.by_total_amount_inputsTarget.classList.add("hidden")
    this.by_order_times_inputsTarget.classList.remove("hidden")
  }

  _restorePanel(parent) {
    Array.from(parent.children).forEach((element) => {
      element.classList.replace("switch-btn-active", "switch-btn-inactive")
    })
  }
}
