import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

export default class extends Controller {
  static values = { id: String }
  connect() {
    if (document.visibilityState === "visible") {
      this.markAsReceived()
    }
  }
  markAsReceived() {
    post("/api/received", { body: { id: this.idValue } })
  }
}
