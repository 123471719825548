import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "chevron"]

  content() {
    this.contentTarget.classList.toggle("h-12")
    this.chevronTarget.classList.toggle("rotate-180")
  }
}
