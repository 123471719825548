import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"
import liff from "@line/liff"

export default class extends Controller {
  static values = {
    liffId: String,
    uniqueKey: String,
    redirectUrl: String,
    destinationUrl: String,
    entryCode: String,
    crmId: Number,
    category: String,
    syncDataPath: String,
  }

  connect() {
    this.initializeLiffOrDie(this.liffIdValue)
  }

  initializeLiffOrDie(myLiffId) {
    if (!myLiffId) {
      // return Rollbar.error('initializeLiffOrDie : Liff ID must exist.')
      console.log("initializeLiffOrDie : Liff ID must exist.")
    } else {
      this.initializeLiff(myLiffId)
    }
  }

  async initializeLiff(myLiffId) {
    liff
      .init({ liffId: myLiffId })
      .then(() => {
        this.initializeApp()
      })
      .catch((err) => {
        console.log(`initializeLiff : ${err.message}`)
      })
  }

  async initializeApp() {
    if (liff.isLoggedIn()) {
      const idToken = await this.getDecodedIDToken()
      await this.updateTaggingsApi(idToken)
      await this.redirectToTargetUrl(idToken)
    } else {
      liff.login({ redirectUri: this.redirectUrlValue })
    }
  }

  getDecodedIDToken(x) {
    return new Promise((resolve) => {
      resolve(liff.getDecodedIDToken())
    })
  }

  async updateTaggingsApi(idToken) {
    const response = await post(this.syncDataPathValue, {
      body: {
        unique_key: this.uniqueKeyValue,
        line_uid: idToken.sub,
        email: idToken.email,
      },
      responseKind: "json",
    })
    if (response.ok) {
      return
    } else {
      throw new Error(result.error)
    }
  }

  async redirectToTargetUrl(idToken) {
    let url = new URL(this.destinationUrlValue)
    if (this.categoryValue == "Sms") {
      url = url.href
      if (liff.getOS() == "android") {
        url = url.replace("&", "?")
      }
    } else {
      let params = new URLSearchParams(url.search.slice(1))
      params.append("crm_id", this.crmIdValue)
      params.append("line_uid", idToken.sub)
      if (this.entryCodeValue) {
        params.append(this.entryCodeValue, idToken.sub)
      }
      url.search = params
      url = url.href
    }
    window.location.replace(url)
  }
}
