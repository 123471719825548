import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu", "button"]
  static values = { items: Array }

  connect() {
    const currentPath = location.pathname.split("/")[1]
    if (this.itemsValue.includes(currentPath)) {
      this.buttonTarget.classList.add("active")
    }
    this._activeNavMenu(this.element.querySelectorAll("a"), currentPath)
  }

  toggle() {
    this.menuTarget.classList.toggle("hidden")
  }

  hide(event) {
    if (this.element.contains(event.target) === false && !this.menuTarget.classList.contains("hidden")) {
      this.menuTarget.classList.add("hidden")
    }
  }

  _activeNavMenu(navMenu, currentPath) {
    for (const [key, value] of Object.entries(navMenu)) {
      const navPath = value.href.split("/")[3]
      if (navPath === currentPath) {
        navMenu[key].classList.add("active")
      }
    }
  }
}
