import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["source"]

  copy() {
    var range, select
    range = document.createRange()
    range.selectNode(this.sourceTarget)
    select = window.getSelection()
    select.removeAllRanges()
    select.addRange(range)
    document.execCommand("copy")
  }
}
