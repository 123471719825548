import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static values = {
    appId: String,
    permissions: String,
  }

  async pagesAuthenticate() {
    await this.initializeFb()
    await this.fbLogin()
  }

  initializeFb() {
    FB.init({
      appId: this.appIdValue,
      version: "v19.0",
      xfbml: true,
    })
  }

  fbLogin() {
    FB.login(
      function (response) {
        if (response.authResponse) {
          Rails.ajax({
            type: "POST",
            dataType: "json",
            url: "/fb_messengers/sync_auth_response",
            data: new URLSearchParams({ auth_response: JSON.stringify(response.authResponse) }).toString(),
            success: function (response) {
              console.log(response)
              // Rollbar.info(response)
            },
            error: function (err) {
              console.log(err)
              // Rollbar.error(err)
            },
          })
          // Rollbar.info(new URLSearchParams({'auth_response': JSON.stringify(response.authResponse)}).toString());
          console.log(new URLSearchParams({ auth_response: JSON.stringify(response.authResponse) }).toString())
        } else {
          // Rollbar.info('User cancelled login or did not fully authorize.');
          console.log("User cancelled login or did not fully authorize.")
        }
      },
      {
        // scope: 'email,pages_show_list,pages_messaging,pages_read_engagement,public_profile,read_insights,pages_manage_metadata,pages_read_user_content,pages_manage_ads,pages_manage_posts,pages_manage_engagement'
        // scope: "email,pages_show_list,pages_messaging,public_profile,pages_manage_metadata,pages_read_engagement,pages_read_user_content",
        scope: this.permissionsValue,
      },
    )
  }
}
