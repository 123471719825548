import { Controller } from "@hotwired/stimulus"
import Litepicker from "litepicker"

export default class extends Controller {
  static targets = ["panel", "track_inputs", "page_inputs", "path", "url", "category_input"]

  track(event) {
    event.preventDefault()
    this._restorePanel(this.panelTarget)

    event.target.classList.replace("switch-btn-inactive", "switch-btn-active")
    this.track_inputsTarget.classList.remove("hidden")
    this.page_inputsTarget.classList.add("hidden")
    this.category_inputTarget.value = "track"
  }

  page(event) {
    event.preventDefault()
    this._restorePanel(this.panelTarget)

    event.target.classList.replace("switch-btn-inactive", "switch-btn-active")
    this.track_inputsTarget.classList.add("hidden")
    this.page_inputsTarget.classList.remove("hidden")
    this.category_inputTarget.value = "path"
  }

  url(event) {
    event.preventDefault()
    this._restorePanel(event.target.parentNode)
    this.urlTarget.classList.remove("hidden")
    this.pathTarget.classList.add("hidden")
    event.target.classList.replace("switch-btn-inactive", "switch-btn-active")
    this.category_inputTarget.value = "url"
  }

  path(event) {
    event.preventDefault()
    this._restorePanel(event.target.parentNode)
    this.urlTarget.classList.add("hidden")
    this.pathTarget.classList.remove("hidden")
    event.target.classList.replace("switch-btn-inactive", "switch-btn-active")
    this.category_inputTarget.value = "path"
  }

  _restorePanel(parent) {
    Array.from(parent.children).forEach((element) => {
      element.classList.replace("switch-btn-active", "switch-btn-inactive")
    })
  }
}
