import { Controller } from "@hotwired/stimulus"
import { fabric } from 'fabric';

export default class extends Controller {
  static values = {
    imageUrl: String,
    canvasId: String,
    areaId: String,
  }

  randomRgbaColorCode() {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgba(${r},${g},${b},0.5)`;
  }

  connect() {
    const canvas = new fabric.Canvas(this.canvasIdValue);
    fabric.Image.fromURL(this.imageUrlValue, (img) => {
      img.set({
        selectable: false,
        evented: false
      });
      canvas.setDimensions({
        width: img.width,
        height: img.height
      });
      canvas.add(img);
    });
    let rect, rects = [], isDrawing = false;
    canvas.on('mouse:down', (event) => {
      // check current mouse position against all rectangles
      // if inside any rectangle then set isDrawing = false and remove the rectangle
      // else create new rectangle
      const pointer = canvas.getPointer(event.e);
      for (let item of rects) {
        if (pointer.x > item.left && pointer.x < item.left + item.width && pointer.y > item.top && pointer.y < item.top + item.height) {
          isDrawing = false;
          canvas.remove(item);
          rects.splice(rects.indexOf(item), 1);
          return;
        }
      }

      rect = new fabric.Rect({
        left: pointer.x,
        top: pointer.y,
        fill: 'transparent',
        stroke: 'red',
        strokeWidth: 2,
        angle: 0,
        fill: this.randomRgbaColorCode(),
        transparentCorners: false,
        selectable: false,
        evented: false,
      });
      canvas.add(rect);
      isDrawing = true;
    });

    canvas.on('mouse:move', (event) => {
      if (isDrawing) {
        const pointer = canvas.getPointer(event.e);
        rect.set({
          width: pointer.x - rect.left,
          height: pointer.y - rect.top
        });
        canvas.renderAll();
      }
    });

    canvas.on('mouse:up', () => {
      rects.push(rect);
      isDrawing = false;
      const coordinates = {
        left: Math.round(rect.left),
        top: Math.round(rect.top),
        width: rect.width,
        height: rect.height
      };

      // Use the coordinates as desired
      // console.log(coordinates);
      // console.log(this.areaIdValue)
      // console.log(document.getElementsByName(`rich_menu[areas_attributes][${this.areaIdValue}][x]`).length)
      document.getElementsByName(`rich_menu[areas_attributes][${this.areaIdValue}][x]`)[0].value = coordinates.left
      document.getElementsByName(`rich_menu[areas_attributes][${this.areaIdValue}][y]`)[0].value = coordinates.top
      document.getElementsByName(`rich_menu[areas_attributes][${this.areaIdValue}][width]`)[0].value = coordinates.width
      document.getElementsByName(`rich_menu[areas_attributes][${this.areaIdValue}][height]`)[0].value = coordinates.height
    });

    canvas.on('object:selected', (event) => {
      console.log('Rectangle selected');
      const selectedObject = event.target;
      if (selectedObject instanceof fabric.Rect) {
        console.log('Rectangle selected');
      }
    });

    canvas.on('selection:cleared', () => {
      console.log('Selection cleared');
    });

  }

  hide() {
    document.getElementById("canvas-container").dataset.rectangleAreaIdValue = ""
    document.getElementById("canvas-container").style.display = "none"
  }
}
