import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"
import liff from "@line/liff"

export default class extends Controller {
  static values = {
    liffId: String,
    redirectUrl: String,
    dest: String,
  }

  connect() {
    this.initializeLiffOrDie(this.liffIdValue)
  }

  initializeLiffOrDie(myLiffId) {
    if (!myLiffId) {
      console.log("initializeLiffOrDie : Liff ID must exist.")
      // return Rollbar.error('initializeLiffOrDie : Liff ID must exist.')
    } else {
      this.initializeLiff(myLiffId)
    }
  }

  async initializeLiff(myLiffId) {
    liff
      .init({ liffId: this.liffIdValue })
      .then(() => {
        this.initializeApp()
      })
      .catch((err) => {
        console.log(`initializeLiff : ${err.message}`)
        // Rollbar.error(`initializeLiff : ${err.message}`)
      })
  }

  async initializeApp() {
    if (liff.isLoggedIn()) {
      const profile = await liff.getProfile()
      let url = new URL(this.redirectUrlValue)
      let params = new URLSearchParams(url.search.slice(1))
      if (profile.userId) {
        params.append("line_uid", profile.userId)
      }
      if (this.destValue) {
        params.append("dest", this.destValue)
      }
      url.search = params
      window.location.replace(url.href)
    } else {
      liff.login()
    }
  }
}
