import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.timesCount = 0
    this.reload()
  }

  reload() {
    if (this.element.width === 0 && this.timesCount < 3) {
      setTimeout(() => {
        this.element.src = `${this.element.src}`
        this.timesCount += 1
        this.reload()
      }, 1500)
    }
  }
}
