import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // check if selector #messages has any children
    if (document.querySelector("#messages").childElementCount > 0) {
      document.querySelector("#messages").lastElementChild.scrollIntoView()
    }

    const observer = new MutationObserver(this.observerCallback)
    observer.observe(this.element, { childList: true })
  }

  observerCallback(mutationList, observer) {
    mutationList.forEach((mutation) => {
      if (mutation.type === "childList") {
        document.querySelector("#messages").lastElementChild.scrollIntoView()
      }
    })
  }
}
