import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "category",
    "text",
    "uri",
    "label",
    "richMenuAlias",
    "richMenuData",
    "textInput",
    "uriInput",
    "labelInput",
    "richMenuAliasInput",
    "richMenuDataInput",
    "xInput",
    "yInput",
    "widthInput",
    "heightInput",
  ]

  static values = {
    "areaId": String,
  }


  get category() {
    return this.categoryTarget.options[this.categoryTarget.selectedIndex].value
  }

  connect() {
    this.switchCategory()
  }

  reveal() {
    document.getElementById("canvas-container").dataset.rectangleAreaIdValue = this.areaIdValue
    document.getElementById("canvas-container").style.display = "block"
  }

  remove() {
    // clear every required on this controller context
    this.labelInputTarget.required = false
    this.textInputTarget.required = false
    this.uriInputTarget.required = false
    this.richMenuAliasInputTarget.required = false
    this.richMenuDataInputTarget.required = false
    this.xInputTarget.required = false
    this.yInputTarget.required = false
    this.widthInputTarget.required = false
    this.heightInputTarget.required = false
  }

  changeCategory() {
    this.switchCategory()
  }

  switchCategory() {

    this.clearAllOptions()
    // this.switchPostback()
    this.switchMessage()
    this.switchURI()
    this.switchRichMenu()
    // this.switchDatetimepicker()
    // this.switchCamera()
    // this.switchCameraRoll()
    // this.switchLocation()
  }

  clearAllOptions() {
    this.labelInputTarget.required = false
    this.textInputTarget.required = false
    this.uriInputTarget.required = false
    this.richMenuAliasInputTarget.required = false
    this.richMenuDataInputTarget.required = false
    this.labelTarget.style.display = "none"
    this.textTarget.style.display = "none"
    this.uriTarget.style.display = "none"
    this.richMenuAliasTarget.style.display = "none"
    this.richMenuDataTarget.style.display = "none"
    this.labelInputTarget.style.display = "none"
    this.textInputTarget.style.display = "none"
    this.uriInputTarget.style.display = "none"
    this.richMenuAliasInputTarget.style.display = "none"
    this.richMenuDataInputTarget.style.display = "none"
  }

  // switchPostback() {
  //   if (this.category === "postback") {
  //     this.labelTarget.style.display = "block"
  //     this.labelInputTarget.required = true
  //     this.dataTarget.style.display = "block"
  //     this.dataInputTarget.required = true
  //     this.textTarget.style.display = "block"
  //     this.textInputTarget.required = true
  //   }
  // }

  switchMessage() {
    if (this.category === "message") {
      this.labelTarget.style.display = "block"
      this.labelInputTarget.style.display = "block"
      // this.labelInputTarget.required = true
      this.textTarget.style.display = "block"
      this.textInputTarget.style.display = "block"
      this.textInputTarget.required = true
    }
  }

  switchURI() {
    if (this.category === "uri") {
      this.labelTarget.style.display = "block"
      this.labelInputTarget.style.display = "block"
      // this.labelInputTarget.required = true
      this.uriTarget.style.display = "block"
      this.uriInputTarget.style.display = "block"
      this.uriInputTarget.required = true
    }
  }

  switchRichMenu() {
    if (this.category === "switch_menu") {
      this.labelTarget.style.display = "block"
      this.labelInputTarget.style.display = "block"
      // this.labelInputTarget.required = true
      this.richMenuAliasTarget.style.display = "block"
      this.richMenuAliasInputTarget.style.display = "block"
      this.richMenuAliasInputTarget.required = true
      this.richMenuDataTarget.style.display = "block"
      this.richMenuDataInputTarget.style.display = "block"
      this.richMenuDataInputTarget.required = true
    }
  }
  // switchDatetimepicker() {
  //   if (this.category === "datetimepicker") {
  //     this.labelTarget.style.display = "block"
  //     this.labelInputTarget.required = true
  //     this.dataTarget.style.display = "block"
  //     this.dataInputTarget.required = true
  //     this.modeTarget.style.display = "block"
  //     this.modeInputTarget.required = true
  //     this.initialTarget.style.display = "block"
  //     this.maxTarget.style.display = "block"
  //     this.minTarget.style.display = "block"
  //   }
  // }

  // switchCamera() {
  //   if (this.category === "camera") {
  //     this.labelTarget.style.display = "block"
  //     this.labelInputTarget.required = true
  //   }
  // }

  // switchCameraRoll() {
  //   if (this.category === "cameraRoll") {
  //     this.labelTarget.style.display = "block"
  //     this.labelInputTarget.required = true
  //   }
  // }

  // switchLocation() {
  //   if (this.category === "location") {
  //     this.labelTarget.style.display = "block"
  //     this.labelInputTarget.required = true
  //   }
  // }
}
