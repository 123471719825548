import { Controller } from "@hotwired/stimulus"
import * as Choices from "choices.js"

export default class extends Controller {
  connect() {
    new Choices(this.element, {
      delimiter: ",",
      removeItemButton: true,
      editItems: true,
      maxItemCount: 13,
      classNames: {
        input: "choices__input",
      },
    })
  }
}
