import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["slidearea", "chevron"]

  toggle() {
    this.slideareaTarget.classList.toggle("scale-y-0")
  }

  dropdown() {
    this.chevronTarget.classList.toggle("rotate-180")
    this.slideareaTarget.classList.toggle("h-8")
  }
}
