import { Controller } from "@hotwired/stimulus"
import { Howl } from "howler"

export default class extends Controller {
  static targets = ["room"]
  static values = { sound: String }
  initialize() {
    this.ids = []
    this.sound = new Howl({ src: [this.soundValue] })
  }

  roomTargetConnected(element) {
    let roomId = element.getAttribute("room-id")
    let shouldPlay = element.getAttribute("should-play-sound")
    if (!this.ids.includes(roomId)) {
      this.ids.push(roomId)
      if (shouldPlay === "true" && element.getAttribute("existing-room") === "false") {
        console.log("play sound")
        this.sound.play()
      }
    }
  }
}
